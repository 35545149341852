/*
    General Helper Class
*/

// example: https://bithacker.dev/javascript-object-multi-property-sort

import React from "react";

// dom
import { useLocation, NavLink } from "react-router-dom";

// services
import StorageService from "../services/StorageService";
import LOCALIZATION from "../services/LocalizationService";

// date helper
import { formatDate } from "./DateHelper";

// constants
import { DATE_FORMAT } from "../constants/DateFormat";
import { ROLES } from "../constants/General";
import { monthNames } from "@timetrack/common/src/constants/General";

// lodash
import _ from "lodash";

// antd
import { Col, Row, Tooltip } from 'antd';

// Icons
import { FileImageOutlined, FileExcelOutlined } from "@ant-design/icons";
import { CgNotes } from "react-icons/cg";
import FILEICON from "@timetrack/web/src/assets/images/SVG/fileIcon.svg"
import PDFICON from "@timetrack/web/src/assets/images/SVG/pdfIcon.svg"


export const isSuperAdmin = () => {
  return getUserGroup() === ROLES.SUPER_ADMIN;
};

export const isAdmin = () => {
  return getUserGroup() === ROLES.ADMIN;
};

export const isManager = () => {
  return getUserGroup() === ROLES.MANAGER;
};

export const isEmployee = () => {
  return getUserGroup() === ROLES.EMPLOYEE;
};

export const isClient = () => {
  return getUserGroup() === ROLES.CLIENT;
};

export const isHR = () => {
  return getUserGroup() === ROLES.HR;
};

export const getName = () => {
  const token = StorageService.instance.getAccessToken() || {};
  const { first_name, last_name } = parseJwt(token)?.user?.user_details || {};

  if (!!first_name && !!last_name) {
    return `${first_name} ${last_name}`;
  } else if (!!first_name) {
    return first_name;
  } else {
    return last_name || LOCALIZATION.USER;
  }
};

export const getNameFromObj = (obj) => {
  const { first_name, last_name } = obj || {};

  if (!!first_name && !!last_name) {
    return `${first_name} ${last_name}`;
  } else if (!!first_name) {
    return first_name;
  } else {
    return last_name || "";
  }
};

export const sumOfArray = (arr) => {
  return arr.reduce((partialSum, a) => partialSum + a, 0) || 0;
};

export const getEmail = () => {
  const token = StorageService.instance.getAccessToken() || {};

  const { email } = parseJwt(token)?.user?.user_details || {};

  return email;
};

export const pdfDownload = (file) => {
  const blob = new Blob([file], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "document.pdf";
  document.body.appendChild(link);
  link.click();
};

// get user
export const getUser = () => {
  const token = StorageService.instance.getAccessToken() || {};
  const user = parseJwt(token)?.user || "";
  return user;
};


export const getUserDetails = () => {
  const token = StorageService.instance.getAccessToken() || {};
  const user = parseJwt(token)?.user?.user_details || "";
  return user;
};

export const getUserGroup = () => {
  const token = StorageService.instance.getAccessToken() || {};

  const userGroup = parseJwt(token)?.user?.roles?.[0] || "";
  return userGroup?.toLowerCase();
};

export const getUserId = () => {
  const token = StorageService.instance.getAccessToken() || {};
  return parseJwt(token)?.user_id;
};

export const getShortName = (name) => {
  const token = StorageService.instance.getAccessToken() || {};

  const { first_name, last_name } = name || parseJwt(token)?.user?.user_details || {};

  if (!!first_name && !!last_name) {
    return first_name?.[0] + last_name?.[0];
  } else if (!!first_name) {
    return first_name?.[0];
  } else if (!!last_name) {
    return last_name?.[0];
  } else {
    return "";
  }
};

export const getInitials = (fullName) => {
  if (!fullName) return "";

  const namesArray = fullName?.split(" ")?.filter(Boolean);
  const initials = namesArray
    ?.map((name) => name?.[0]?.toUpperCase())
    ?.join("");

  if (initials?.length > 2) {
    return (
      <Tooltip title={initials}>{initials?.slice(0, 2)}</Tooltip>
    );
  } else return initials;
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const disabledFutureDates = (current) => {
  return current && current.valueOf() > Date.now();
};

// Sort an array
export function sortArray(array = [], sortBy) {
  return (
    array &&
    array.sort(function (a, b) {
      let i = 0,
        result = 0;
      while (i < sortBy.length && result === 0) {
        result =
          sortBy[i].direction *
          (a[sortBy[i]?.prop]?.toString() < b[sortBy[i]?.prop]?.toString()
            ? -1
            : a[sortBy[i]?.prop]?.toString() > b[sortBy[i]?.prop]?.toString()
              ? 1
              : 0);
        i++;
      }
      return result;
    })
  );
}

export function truncateString(str, n) {
  if (str?.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

// Get Int value of given value
export function getIntVal(value) {
  return parseInt(value || 0);
}

// Add Thousand Separator in value
export function thousandSeprator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Get query params from url
export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function paramFormater(fl) {
  // eslint-disable-next-line array-callback-return
  Object.keys(fl || {})?.map((key) => {
    if (key?.includes("date")) {
      if (fl[key] === "Invalid Date") {
        fl[key] = null;
      }
      fl[key] = !!fl[key]
        ? formatDate(new Date(fl[key]), DATE_FORMAT.YEAR_MONTH_DAY)
        : null;
    } else if (Array.isArray(fl[key])) {
      fl[key] = fl?.[key]?.toString();
    }
  });

  return fl;
}

export function getQueryParams(object) {
  let params = new URLSearchParams();

  for (const property in object) {
    let value = object[property];

    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(property, item);
        });
      } else {
        params.append(property, value);
      }
    }
  }

  return params;
}

export const toBase64 = async (file) => {
  const action = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  const base64String = await action;
  let result = base64String?.split(",")?.[1];
  return result;
};

export function getTooltipProps() {
  // Is New Job
  const isReplacement = true;

  return {
    placement: "topRight",
    color: !!isReplacement ? "#DD9510" : "#268f99",
  };
}

export function getFiltersCount(filterObj = {}) {
  let count = 0;
  filterObj &&
    Object.keys(filterObj)?.map((key) => {
      const value = filterObj?.[key];
      const isArray = Array.isArray(value);
      if ((!isArray && !!value) || (!!isArray && !!value?.length > 0)) {
        count++;
      }
    });
  return count;
}

export function isObjectEmpty(obj) {
  return (
    !!obj &&
    !!!Object.keys(obj)
      ?.map((key) => !!obj?.[key])
      ?.filter((i) => i != false)?.length
  );
}

export function getRowBackground(jobData) {
  let color = null;
  if (!!jobData?.admin_approval && jobData?.job_status === "Completed") {
    color = "green";
  } else if (!jobData?.admin_approval && jobData?.job_status === "Completed") {
    color = "yellow";
  }
  if (!jobData?.contractor_approval && jobData?.job_status === "Completed") {
    color = "red";
  }
  // if (!!st?.row && checkKPIStatus(st)) {
  //   color = "orange";
  // }

  return color;
}

function unsecuredCopyToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
}

export function copyToClipboard(content) {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content);
  } else {
    unsecuredCopyToClipboard(content);
  }
}

export function parseGeneralErrorMessage(object) {
  let message = "";
  for (const property in object) {
    message += object[property]?.toString() + ", ";
  }
  return message.substring(0, message.length - 2);
}

export function isValueValid(value) {
  return value !== null && value !== undefined && value !== "";
}

export const capitalizeWords = (name) => {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Time Format to HH:MM:SS
export const TimeFormat = (value) => {
  const time = new Date(value);

  // Convert to hh:mm:ss format, and handle hour 24 as 00
  let formattedTime = time.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  if (formattedTime.startsWith("24")) {
    formattedTime = formattedTime.replace("24", "00");
  }

  return formattedTime;
};

export const getFillableFilters = (obj) => {
  const result = _.omit(_.cloneDeep(obj), [
    "page",
    "limit",
    "search",
    "ordering",
  ]);
  return result;
};

export function formatBreadcrumb(path) {
  path = path.replace(/^\/|\/$/g, "");
  const parts = path.split("/");
  const formattedParts = [];
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].match(/[^A-Za-z0-9]/)) {
      break;
    }
    formattedParts?.push(
      " " + parts?.[i]?.charAt(0)?.toUpperCase() + parts?.[i]?.slice(1)
    );
  }
  return formattedParts.join(" / ");
}

export function extractParamsFromString(str) {
  const parts = str.split("/");
  const lastPart = parts[parts.length - 1];
  const keyValuePairs = lastPart.split("&");
  const params = {};
  for (const pair of keyValuePairs) {
    const [key, value] = pair.split("=");
    if (
      value !== undefined &&
      value !== "undefined" &&
      value !== "" &&
      value !== null
    ) {
      params[key] = value;
    }
  }
  return params;
}

export function objectToQueryString(params) {
  return Object.entries(params).filter(
    ([key, value]) => value !== undefined && value !== null && value !== ""
  )?.flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value?.map(
        (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
      );
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }).join("&");
}

export function extractFirstLetterOfWords(str) {
  const words = str?.split(' ');
  const firstLetters = words?.map(word => word?.charAt(0))?.join('');
  return firstLetters;
}

export function getRandomColor() {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

export function getCurrentMonthDates() {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const daysInMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  ).getDate();

  const currentMonthDates = Array.from({ length: daysInMonth }, (_, i) => {
    const date = new Date(firstDayOfMonth);
    date.setDate(firstDayOfMonth.getDate() + i);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  });

  return currentMonthDates;
}

export function getCurrentMonth() {
  const today = new Date();
  const currentMonth = monthNames[today.getMonth()];
  return currentMonth;
}

export const extensionBasedIcons = {
  pdf: <img src={PDFICON} alt="" height={32} width={32} />,
  jpg: <FileImageOutlined />,
  jpeg: <FileImageOutlined />,
  png: <FileImageOutlined />,
  doc: <CgNotes />,
  docx: <CgNotes />,
  xls: <FileExcelOutlined />,
  xlsx: <FileExcelOutlined />,
  default: <img src={FILEICON} alt="" height={32} width={32} />,
};

export function cleanAndCapitalize(input) {
  let cleanedInput = input?.replace(/[^a-zA-Z0-9\s.()]/g, ' ');
  let capitalizedInput = cleanedInput?.replace(/\b\w/g, char => char?.toUpperCase());
  return capitalizedInput;
}

export function extractFileNameAndExtension(inputString, type) {
  const fileNameWithExtension = inputString?.substring(inputString?.lastIndexOf('/') + 1);
  const lastDotIndex = fileNameWithExtension?.lastIndexOf('.');
  const fileName = fileNameWithExtension?.substring(0, lastDotIndex);
  const extension = fileNameWithExtension?.substring(lastDotIndex + 1);
  if (type === 'name') {
    return fileName;
  } else if (type === 'extension') {
    return extension;
  } else {
    return null;
  }
}

export const isImageFile = (url) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const extension = url?.split('.')?.pop()?.toLowerCase();
  return imageExtensions?.includes(extension);
};

export const isVideoFile = (fileUrl) => {
  const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'wmv', 'flv', 'mkv'];
  const fileExtension = fileUrl?.split('.')?.pop()?.toLowerCase();
  return videoExtensions?.includes(fileExtension);
};

export const isDocumentFile = (fileUrl) => {
  const docExtensions = ['pdf', 'xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx'];
  const fileExtension = fileUrl?.split('.')?.pop()?.toLowerCase();
  return docExtensions?.includes(fileExtension);
};

export const addToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const convertTimeString = (input) => {
  if (!input || typeof input !== 'string') {
    return "0 Hrs";
  }

  const regex = /(\d+\.?\d*)\s*(hours?|hrs?|minutes?|mins?|seconds?|secs?)/gi;
  let totalHours = 0;

  let match;
  while ((match = regex.exec(input)) !== null) {
    const value = parseFloat(match[1]);
    const unit = match[2].toLowerCase();

    if (unit.includes('hour')) {
      totalHours += value;
    } else if (unit.includes('min')) {
      totalHours += value / 60;
    } else if (unit.includes('sec')) {
      totalHours += value / 3600;
    }
  }

  return `${totalHours.toFixed(1)} Hrs`;
}
// modify tasks array to add new rows bases on rates
export const prepareModifiedTasks = (array) => {
  return array?.flatMap(task => {
    const { new_rates, timelogs } = task;

    // Check if new_rates is an array with elements
    if (!!new_rates && Array.isArray(new_rates) && new_rates?.length > 0) {
      return new_rates?.map(rateObj => {
        if (rateObj?.rate_1 && rateObj?.rate_1_total_hours > 0) {

          const rate1Timelogs = timelogs?.length > 0
            ? timelogs?.filter(log => log?.employee_rate_type === "rate_1")
            : [];

          return {
            ...task,
            // taskName: `${task?.taskName} : Rate 1`,
            totalLoggedHoursTask: rateObj?.rate_1_total_hours,
            amount: rateObj?.rate_1_total_amount,
            rate: parseInt(rateObj?.rate_1),
            taskId: `${task?.taskId}_rate_1`,
            timelogs: rate1Timelogs
          };
        } else if (rateObj?.rate_2 && rateObj?.rate_2_total_hours > 0) {

          const rate2Timelogs = timelogs?.length > 0
            ? timelogs?.filter(log => log?.employee_rate_type === "rate_2")
            : [];

          return {
            ...task,
            // taskName: `${task?.taskName} : Rate 2`,
            totalLoggedHoursTask: rateObj?.rate_2_total_hours,
            amount: rateObj?.rate_2_total_amount,
            rate: parseInt(rateObj?.rate_2),
            taskId: `${task?.taskId}_rate_2`,
            timelogs: rate2Timelogs
          };
        }
        return null;
      }).filter(Boolean);
    }
    return task;
  });
};

const clickUpColorPalette = [
  "#FFABAB", // Light Red
  "#FFC3A0", // Light Peach
  "#FF677D", // Soft Red-Pink
  "#D4A5A5", // Soft Muted Red
  "#392F5A", // Dark Purple
  "#B3A0D4", // Light Lavender
  "#6B4226", // Brownish
  "#D9BF77", // Muted Yellow
  "#ACD8AA", // Pale Green
  "#FF9B85", // Coral Orange
  "#6B9AC4", // Soft Blue
  "#BFD8D2", // Mint Green
  "#F0A6CA", // Light Pink
  "#E3B9B2", // Soft Coral
  "#9A9E9D", // Greyish Green
  "#F7E1D7", // Light Peach
  "#A9E2F3", // Light Sky Blue
  "#F5D0A9", // Light Apricot
  "#FFB5E8", // Lavender Pink
  "#B8A9C9", // Lavender Gray
  "#FFCFD2", // Blush Pink
  "#E4C6A5", // Sandy Beige
  "#B0C4B1", // Soft Olive Green
  "#B5D8C4", // Pale Mint Green
  "#F6D1C1", // Peachy Beige
  "#8F5C5C", // Muted Red
  "#6D6E71", // Cool Gray
  "#A2DFF7", // Soft Blue
  "#C7D5D7", // Light Cool Gray
  "#F3D6B1", // Soft Light Yellow
  "#B4D3B5", // Light Green
  "#C9D7F4", // Pastel Blue
  "#E1B8A1", // Soft Tan
  "#D5B0D6", // Lavender
  "#FFB3C1", // Pastel Pink
  "#BCC6CC", // Pale Gray
  "#A3B9C7", // Light Blue-Green
  "#98C7A1", // Light Olive Green
  "#B8E0D2", // Pale Sea Green
  "#F2D1D1", // Light Red-Pink
  "#F3F1F2", // Soft Light Gray
  "#D0D0D6", // Dusty Lavender
  "#F7B7A3", // Soft Peachy
  "#C9C7E3", // Light Lavender
  "#F1C9B7", // Soft Coral
  "#B0E0E6", // Powder Blue
  "#BEE2B2", // Light Mint Green
  "#F7C8B4", // Pastel Coral
  "#D2B3C1", // Pale Rose
  "#FFFD87", // Light Yellow
  "#9B8A76", // Dusty Olive
  "#FFD7BE", // Soft Peach
  "#F4C7A1", // Soft Apricot
  "#A3D8F4", // Light Cyan
  "#FF84A1", // Light Hot Pink
  "#F1E1D1", // Pale Cream
  "#6D9EC1", // Soft Sky Blue
  "#D1D8E0", // Light Misty Blue
  "#A9B7A1", // Dusty Green
  "#FFBC00", // Soft Yellow
  "#B7C3F3", // Light Lavender Blue
  "#D4D4D9", // Soft Light Gray
  "#9A7F80", // Muted Red-Brown
  "#D5E1E4", // Cool Mint
  "#F9D5E5", // Soft Pink
  "#A7C4B5", // Light Sage Green
  "#D4D8D4", // Soft Gray-Green
  "#D5C6E5", // Pastel Purple
  "#B4A8B8", // Dusty Lavender
  "#C4D8E2", // Soft Light Blue
  "#F9B3A6", // Light Salmon
  "#C0C0E5", // Light Cool Blue
  "#E2D0C7", // Soft Beige-Pink
  "#92C9F5", // Light Blue
  "#D2C2F5", // Light Purple
  "#A1D1A6", // Soft Olive
  "#7F91B5", // Cool Grayish Blue
  "#F6C6D2", // Soft Coral-Pink
  "#C8C9F2", // Lavender Blue
  "#9EC1B0", // Light Sage Green
  "#A8D0C1", // Soft Mint Blue
  "#D9D3B3", // Soft Olive
  "#F1D1D6", // Soft Pink
  "#D5F3E0", // Soft Pale Green
  "#E6D1D1", // Soft Blush
  "#F1D1E0"  // Light Pastel Pink
];



// Hash function to generate a unique color index from a string
const getColorIndex = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash % clickUpColorPalette?.length);
};

// Function to get color for a user
export const getUserColor = (userId, userName) => {
  // Combine user ID and name for a unique key
  const uniqueKey = `${userId}-${userName}`;
  const colorIndex = getColorIndex(uniqueKey);
  return clickUpColorPalette[colorIndex];
};

export const cleanObject = (obj) => {
  const safeObj = obj ?? {};
  return Object.fromEntries(
    Object.entries(safeObj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
};

export const SetNameRoutesAndAvatar = ({ name, nameId, nameLocation, isAvatar = true }) => {
  const hasValidRoute = !!nameId && !!nameLocation;
  const url = hasValidRoute ? nameLocation.replace(':id', nameId) : null;
  return (
    !!name && <Row className='align-center'>
      {!!isAvatar && <Col
        className='avatar mr-2'
        style={{ backgroundColor: getUserColor(nameId, name) }}
      >{getInitials(name)}</Col>}
      <Col className="userName">
        {hasValidRoute ? (
          <NavLink
            to={url}>
            {name}
          </NavLink>
        ) : (
          <span>{name}</span>
        )}
      </Col>
    </Row>
  )
}

export function findKeyByValue(obj, value) {
  for (const key in obj) {
    if (obj?.[key] === value) {
      return key; // Agar value mil jaye to key return karo
    }
  }
  return null; // Agar value na mile to null return karo
}